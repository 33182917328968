import { createModule } from 'vuexok'
import store from '.'
import { cloneDeep } from 'lodash-es'
import { logBreadcrumb } from '@/core/logger'
import { apolloClient } from '@/plugins/apollo/default'
import { ObservableQuery } from '@apollo/client'
import { waitTimer } from '@/core/pendings'
import { terminalService } from '@/store/terminalService'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import {
  priceModificationUpdated,
  PriceModificationQuery,
  PriceModificationQueryVariables,
  PriceModificationUpdatedSubscription,
  PriceModificationUpdatedSubscriptionVariables,
  priceModification
} from '@/graphql/default/priceModification.graphql'

type State = {
  priceModification: PriceModificationQuery['priceModification']
}
const tag = 'priceModificationModule'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

function createPriceModificationModule() {
  log('createPriceModificationModule')

  let observableQuerySubcategory: ObservableQuery<PriceModificationQuery, PriceModificationQueryVariables> | undefined

  const state: State = {
    priceModification: null
  }

  /**
   * Загрузка скидок
   */
  async function loadPrice() {
    const prices = (await apolloClient.query({
      query: priceModification
    })).data.priceModification
    vuexModule.mutations.setPriceModification(prices)
  }

  const vuexModule = createModule(tag, {
    namespaced: true,
    state,
    actions: {
      async startWatchingSubscriptionsAndQueries() {
        log('Запускаем процесс отслеживания состояние скидок')
        const timeoutBetweenRetries = 60e3
        try {
          if (observableQuerySubcategory) {
            log('Обновляем скидки')
            await observableQuerySubcategory.refetch()
          } else {
            log('Подписываемся на скидки')
            const mac = await terminalService.getMAC()
            const subscribeVariables = {
              mac
            }

            observableQuerySubcategory = apolloClient
              .watchQuery<PriceModificationQuery, PriceModificationQueryVariables>({
                query: priceModification,
                errorPolicy: 'all'
              })
            observableQuerySubcategory.subscribe(
              ({ data: { priceModification } }) => {
                vuexModule.mutations.setPriceModification(priceModification)
              },
              () => {
                waitTimer(timeoutBetweenRetries)
                  .catch(vuexModule.actions.startWatchingSubscriptionsAndQueries)
              }
            )
            observableQuerySubcategory.subscribeToMore(new SubscribeOptions<PriceModificationQuery,
              PriceModificationUpdatedSubscription,
              PriceModificationUpdatedSubscriptionVariables,
              PriceModificationQueryVariables>({
                type: 'updated',
                subscribeDocument: priceModificationUpdated,
                queryDocument: priceModification,
                variables: subscribeVariables
              }))
          }
        } catch {
          waitTimer(timeoutBetweenRetries)
            .catch(vuexModule.actions.startWatchingSubscriptionsAndQueries)
        }
      }
    },
    mutations: {
      setPriceModification(state, prices: PriceModificationQuery['priceModification']) {
        state.priceModification = cloneDeep(prices)
      }
    },
    getters: {
      priceModification(state) {
        return state.priceModification
      }
    }
  })
  vuexModule.register(store)

  vuexModule.actions.startWatchingSubscriptionsAndQueries()

  return {
    get priceModification(): PriceModificationQuery['priceModification'] {
      return vuexModule.getters.priceModification
    },
    /**
     * Загрузка скидок
     */
    async load() {
      await loadPrice()
    }
  }
}

export const priceModificationModule = createPriceModificationModule()

Object.assign(window, { priceModificationModule })

export default priceModificationModule
