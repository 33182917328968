import Vue from 'vue'
import VueRouter, { RawLocation, Route, RouteConfig } from 'vue-router'
import { userComeDebounce } from '@/store/userTracker'
import { settingsModule } from '@/store/settings'
import { cart } from '@/store/cart/cartModule'
import { operator } from '@/store/operator'
import { waitTimer } from '@/core/pendings'
import categoryModule from '@/store/category'
import leftoversModule from '@/store/leftovers'
import priceModificationModule from '@/store/priceModification'
import connectionModule from '@/store/connection'
import { logBreadcrumb } from '@/core/logger'
import { NavigationGuard, NavigationGuardNext } from 'vue-router/types/router'
import Sentry from '@/plugins/sentry'
import terminalModule from '@/store/terminal'
const index = () => import('../pages/index.vue')
const main = () => import('../layouts/main.vue')
const catalog = () => import('../pages/catalog.vue')
const terminalLog = () => import('../pages/terminalLog/terminalLog.vue')
const scanner = () => import('../pages/scanner.vue')
const store = () => import('../pages/store.vue')
const product = () => import('../pages/product.vue')
const basket = () => import('../pages/basket.vue')
const payment = () => import('../pages/payment.vue')
const lockScreen = () => import('../pages/lockScreen.vue')
const developerPanel = () => import('../pages/developerPanel.vue')
const scannerFailed = () => import('../pages/scannerFailed.vue')
const serviceBasket = () => import('../pages/serviceBasket.vue')
const inventory = () => import('../pages/inventory.vue')

const tag = 'router'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'Olive' }, ...arg)

// для скрытия ошибки "NavigationDuplicated"
const superPush = VueRouter.prototype.push
VueRouter.prototype.push = async function push(loc:RawLocation):Promise<Route> {
  try {
    return await superPush.bind(this)(loc)
  } catch (e) {
    if (e?.name === 'NavigationDuplicated') {
      console.warn(e)
      return e
    } else {
      throw e
    }
  }
}

Vue.use(VueRouter)

export enum RouteNames {
  index='INDEX',
  layoutMain='LAYOUT_MAIN',
  catalog='CATALOG',
  store='STORE',
  product='PRODUCT',
  basket='BASKET',
  payment='PAYMENT',
  terminalLog='TERMINAL_LOG',
  scanner='SCANNER',
  development='DEVELOPMENT',
  lock='LOCK',
  foodcard='FOODCARD',
  scannerFailed='SCANNER-FAILED',
  serviceBasket='SERVICEBASKET',
  inventory='INVENTORY',
}

const checkDemoTerminal: NavigationGuard = (to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (terminalModule.getters.terminal?.isDemoMode) {
    next({
      name: RouteNames.catalog
    })
  } else {
    next()
  }
}

const routes: Array<RouteConfig> = [
  {
    name: RouteNames.index,
    path: '/',
    component: index,
    beforeEnter: checkDemoTerminal
  },
  {
    name: RouteNames.layoutMain,
    path: '/',
    component: main,
    children: [
      {
        name: RouteNames.catalog,
        path: '/catalog',
        component: catalog,
        redirect: { name: RouteNames.store },
        children: [
          {
            name: RouteNames.store,
            path: '/',
            component: store,
            props({ query: { search, category, subcategory } }) {
              return {
                search,
                category,
                subcategory
              }
            }
          },
          {
            name: RouteNames.product,
            path: ':id',
            component: product,
            props({ params: { id } }) {
              return { id }
            }
          },
          {
            name: RouteNames.basket,
            path: '/basket',
            component: basket,
            beforeEnter: checkDemoTerminal
          },
          {
            name: RouteNames.payment,
            path: '/payment',
            component: payment,
            props({ query: { provider } }) {
              return { provider }
            },
            beforeEnter: checkDemoTerminal
          },
          {
            name: RouteNames.scannerFailed,
            path: '/scanner-failed',
            component: scannerFailed,
            props: ({ query: { text } }) => ({ text }),
            beforeEnter: checkDemoTerminal
          },
          {
            name: RouteNames.serviceBasket,
            path: '/service',
            component: serviceBasket
          },
          {
            name: RouteNames.inventory,
            path: '/inventory',
            component: inventory,
            props({ query: { action } }) {
              return { action }
            }
          }
        ]
      }
    ]
  },
  {
    name: RouteNames.terminalLog,
    path: '/terminal-log',
    component: terminalLog
  },
  {
    name: RouteNames.scanner,
    path: '/scanner',
    component: scanner,
    beforeEnter: checkDemoTerminal
  },
  {
    name: RouteNames.development,
    path: '/developer-panel',
    alias: ['/registration'],
    component: developerPanel,
    beforeEnter(to, from, next) {
      Sentry.captureMessage('Переходим на страницу для разработчиков')
      next()
    }
  },
  {
    name: RouteNames.lock,
    path: '/lock',
    component: lockScreen,
    beforeEnter(to, from, next) {
      Sentry.captureMessage('Блокировка терминала')
      next()
    }
  },
  {
    path: '*',
    redirect: {
      name: RouteNames.index
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: settingsModule.state.requiredSettings.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  userComeDebounce()
  // при первом заходе в приложение
  if (!from.name) {
    router.push({ name: RouteNames.lock })
    Sentry.captureMessage('Загрузка приложения')
    terminalModule.actions.startWatchingSubscriptionsAndQueries()
    // ждём загрузку важных данные перед разблокировкой интерфейса
    leftoversModule.load()
      .then(() => {
        return router.push({ name: RouteNames.index })
      })
    priceModificationModule.load()
  }
  // очищаем страницу при переходе на главную
  if (to.name === RouteNames.index) {
    cart.reset()
    if (operator.operatorId) {
      operator.resetServiceMode()
    }
  }
})

export default router

async function listenerStatsServices(
  available?: boolean, oldAvailable?:boolean
) {
  // от хорошего к плохому
  if (!available) {
    log('Пропало соединение с сервисами', connectionModule.state)
    waitTimer(10 * 1e3, 1e3, () => connectionModule.getters.allServicesAreAvailable)
      .catch(async() => {
        log('не дождались соединения')
        if (
          router.currentRoute.name !== RouteNames.development &&
          router.currentRoute.name !== RouteNames.lock
        ) {
          await router.push({ name: RouteNames.lock })
        }
      })
  }

  // от плохого к хорошему
  if (!oldAvailable && available) {
    log('Восстановили соединение', connectionModule.state)
    await Promise.all([
      categoryModule.actions.startWatchingSubscriptionsAndQueries(),
      leftoversModule.load(),
      priceModificationModule.load()
    ])
    if (router?.currentRoute?.name === RouteNames.development ||
      router?.currentRoute?.name === RouteNames.lock) {
      await router.push({ name: RouteNames.index })
    }
  }
}

connectionModule.watch(
  (s, g) => g.allServicesAreAvailable,
  listenerStatsServices,
  { immediate: true }
)
