import {
  terminalRegistration,
  TerminalRegistrationMutationVariables
} from '@/graphql/default/registration.graphql'
import { getMAC as getMacGql, GetMacQuery } from '@/graphql/terminal/getMAC.graphql'
import { apolloClient } from '@/plugins/apollo/default'
import { apolloClient as terminalClient } from '@/plugins/apollo/terminal'
import router, { RouteNames } from '@/router'
import { waitTimer } from '@/core/pendings'
import { logBreadcrumb } from '@/core/logger'
import Sentry from '@/plugins/sentry'
import leftoversModule from '@/store/leftovers'
import {
  barcodeAdded,
  BarcodeAddedSubscription,
  BarcodeAddedSubscriptionVariables,
  generateNalunchQrCode as generateNalunchQrCodeGql,
  GenerateNalunchQrCodeMutation,
  GenerateNalunchQrCodeMutationVariables,
  loyaltyCardCodeAdded,
  LoyaltyCardCodeAddedSubscription,
  LoyaltyCardCodeAddedSubscriptionVariables,
  personalCodeAdded,
  PersonalCodeAddedSubscription,
  PersonalCodeAddedSubscriptionVariables,
  yandexCodeAdded,
  YandexCodeAddedSubscription,
  YandexCodeAddedSubscriptionVariables,
  makePayment as makePaymentGql,
  MakePaymentMutation,
  MakePaymentMutationVariables,
  saveFoodcardAuthData as saveFoodcardAuthDataGql,
  SaveFoodcardAuthDataMutation,
  SaveFoodcardAuthDataMutationVariables,
  saveNaLunchAuthData as saveNaLunchAuthDataGql,
  SaveNaLunchAuthDataMutation,
  SaveNaLunchAuthDataMutationVariables,
  saveYandexGOAuthData as saveYandexGOAuthDataGql,
  SaveYandexGoAuthDataMutation,
  SaveYandexGoAuthDataMutationVariables,
  saveYandexBadgeAuthData as saveYandexBadgeAuthDataGql,
  SaveYandexBadgeAuthDataMutation,
  SaveYandexBadgeAuthDataMutationVariables,
  systemState,
  SystemStateQuery,
  SystemStateQueryVariables
} from '@/graphql/terminal/terminalService.graphql'
import { FetchResult } from '@apollo/client'
import { cart } from '@/store/cart/cartModule'
import { customer } from '@/store/customer'
import { operator } from '@/store/operator'
import {
  terminalLogCreate,
  TerminalLogCreateMutation,
  TerminalLogCreateMutationVariables
} from '@/graphql/default/terminalLogs.graphql'
import { TerminalLogTypes } from '@/graphql/default/graphql.schema'
import { userComeDebounce } from '@/store/userTracker'

const tag = 'terminalServiceModule'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...arg:any[]) => logBreadcrumb({ tag, color: '#0A6038' }, ...arg)

type ScannerSubscription =
  | BarcodeAddedSubscription
  | LoyaltyCardCodeAddedSubscription
  | PersonalCodeAddedSubscription
  | YandexCodeAddedSubscription
type ScannerSubscriptionVariables =
  | BarcodeAddedSubscriptionVariables
  | LoyaltyCardCodeAddedSubscriptionVariables
  | PersonalCodeAddedSubscriptionVariables
  | YandexCodeAddedSubscriptionVariables
/**
 * Получение MAC адреса с локального сервиса
 */
function createTerminalService(
  onScanner?:(r: FetchResult<ScannerSubscription>)=>void
) {
  async function startWatchingSubscriptionsAndQueries() {
    log('Запускаем отслеживание состояния')
    if (onScanner) {
      const queries = [barcodeAdded, loyaltyCardCodeAdded, personalCodeAdded, yandexCodeAdded]
      for (const query of queries) {
        terminalClient.subscribe<ScannerSubscription,
          ScannerSubscriptionVariables>({
            query
          })
          .subscribe(onScanner, async(error) => {
            log('Получили ошибку при подписке на сканер', { error })
            try {
              await waitTimer(30 * 1e3)
            } catch {
              await startWatchingSubscriptionsAndQueries()
            }
          })
      }
    }
  }

  /**
   * Получение MAC адреса с локального сервиса
   */
  async function getMAC(): Promise<string> {
    log('Получаем MAC адрес с локального сервиса')
    try {
      const {
        data: {
          macAddress
        }
      } = await terminalClient.query<GetMacQuery>({
        query: getMacGql
      })
      if (!macAddress) {
        throw new Error('Не найден MAC адрес')
      }
      Sentry.setUser({ id: macAddress })
      return macAddress
    } catch (e) {
      const currentRouteName = router.currentRoute.name
      if (currentRouteName !== RouteNames.lock && currentRouteName !== RouteNames.development) {
        await router.push({ name: RouteNames.lock })
      }
      throw e
    }
  }

  /**
   * Регистрация терминала
   * @param state
   */
  async function checkIn(
    { authToken }: TerminalRegistrationMutationVariables)
  : Promise<void> {
    log('Регистрируем терминал')
    await apolloClient.mutate({
      mutation: terminalRegistration,
      variables: { authToken }
    })
  }

  /**
   * Выполнение платежа через Сбербанк и возврат платежной транзакции
   * @param context
   */
  async function makePayment({
    cartId,
    price,
    items,
    provider
  }: MakePaymentMutationVariables): Promise<string> {
    log('Запускаем платёж на терминале', { cartId, price, provider })
    cart.setShowYandexCodeText(true)

    const data = await terminalClient
      .mutate<MakePaymentMutation, MakePaymentMutationVariables>({
        mutation: makePaymentGql,
        variables: {
          cartId,
          price,
          items,
          provider
        }
      })

    cart.setShowYandexCodeText(false)
    const transactionNumber = data.data?.makePayment?.transactionNumber
    if (!transactionNumber) {
      throw new Error('Не получили transactionNumber')
    }
    return transactionNumber
  }

  /**
   * Генерация QR кода для оплаты через сервис Наланч
   */
  async function generateNaLunchQrCode({
    cartId,
    price
  }: GenerateNalunchQrCodeMutationVariables): Promise<string> {
    const result = await terminalClient
      .mutate<GenerateNalunchQrCodeMutation, GenerateNalunchQrCodeMutationVariables>({
        mutation: generateNalunchQrCodeGql,
        variables: {
          cartId,
          price
        }
      })
    const qrContent = result.data?.generateNalunchQrCode?.qrCode
    if (!qrContent) {
      throw new Error('Ошибка при генерации Qr кода для оплаты через Наланч')
    }
    return qrContent
  }

  /**
   * Получить текущее состояние настройки системы
   */
  async function getSystemState() {
    log('Получаем текущее состояние настроек системы')
    const {
      data
    } = await terminalClient
      .query<SystemStateQuery, SystemStateQueryVariables>(
        {
          query: systemState,
          fetchPolicy: 'network-only'
        })
    return data.systemState
  }

  /**
   * Передача авторизационных данных Foodcard
   */
  async function saveFoodcardAuthData({
    authId,
    authToken
  }: SaveFoodcardAuthDataMutationVariables) {
    log('Сохраняем авторизационные данные для FoodCard', { authId, authToken })
    return await terminalClient
      .mutate<SaveFoodcardAuthDataMutation,
        SaveFoodcardAuthDataMutationVariables>({
          mutation: saveFoodcardAuthDataGql,
          variables: { authId, authToken }
        })
  }

  /**
   * Передача авторизационных данных YandexGo
   */
  async function saveYandexGoAuthData({
    authToken,
    mcc
  }: SaveYandexGoAuthDataMutationVariables) {
    log('Сохраняем авторизационные данные для YandexGo', { authToken, mcc })
    return await terminalClient
      .mutate<SaveYandexGoAuthDataMutation,
        SaveYandexGoAuthDataMutationVariables>({
          mutation: saveYandexGOAuthDataGql,
          variables: { authToken, mcc }
        })
  }

  /**
   * Передача авторизационных данных YandexBadge
   */
  async function saveYandexBadgeAuthData({
    authToken
  }: SaveYandexBadgeAuthDataMutationVariables) {
    log('Сохраняем авторизационные данные для YandexBadge', { authToken })
    return await terminalClient
      .mutate<SaveYandexBadgeAuthDataMutation,
        SaveYandexBadgeAuthDataMutationVariables>({
          mutation: saveYandexBadgeAuthDataGql,
          variables: { authToken }
        })
  }

  /**
   * Передача авторизационных данных NaLunch
   */
  async function saveNaLunchAuthData({
    authToken
  }: SaveNaLunchAuthDataMutationVariables) {
    log('Сохраняем авторизационные данные для NaLunch', { authToken })
    return await terminalClient
      .mutate<SaveNaLunchAuthDataMutation,
        SaveNaLunchAuthDataMutationVariables>({
          mutation: saveNaLunchAuthDataGql,
          variables: { authToken }
        })
  }

  startWatchingSubscriptionsAndQueries()

  return {
    getMAC,
    checkIn,
    makePayment,
    getSystemState,
    generateNaLunchQrCode,
    saveFoodcardAuthData,
    saveNaLunchAuthData,
    saveYandexGoAuthData,
    saveYandexBadgeAuthData
  }
}

export const terminalService = createTerminalService(
  async({
    data
  }) => {
    log('Обрабатываем результат сканирования', data)
    userComeDebounce()
    if (!data) {
      throw new Error('Ошибка сканирования')
    }
    if ('barcodeAdded' in data) {
      const barcode = data.barcodeAdded.barcode
      if (barcode) {
        if (/^http/.test(barcode)) {
          Sentry.captureMessage('Сканирована http ссылка')
          apolloClient.mutate<TerminalLogCreateMutation, TerminalLogCreateMutationVariables>({
            mutation: terminalLogCreate,
            variables: {
              logType: TerminalLogTypes.BarcodeFailed,
              message: JSON.stringify({
                message: `Сканирована http ссылка "${barcode}"`
              })
            }
          })
          await router.push({
            name: RouteNames.scannerFailed,
            query: {
              text: 'Вы отсканировали ссылку на страницу производителя. ' +
                '\n Пожалуйста, отсканируйте штрих-код товара.'
            }
          })
        } else {
          const leftover = await leftoversModule.findLeftoverByBarcode(barcode)
          const productId = leftover?.node?.product.id
          if (!productId) {
            Sentry.captureMessage(`Товар "${barcode}" не найден.`)
            apolloClient.mutate<TerminalLogCreateMutation, TerminalLogCreateMutationVariables>({
              mutation: terminalLogCreate,
              variables: {
                logType: TerminalLogTypes.BarcodeFailed,
                message: JSON.stringify({
                  message: `Товар с баркодом "${barcode}" не найден.`
                })
              }
            })
            await router.push({
              name: RouteNames.scannerFailed,
              query: {
                text: `Товар с таким баркодом "${barcode}" не найден в ассортименте терминала.`
              }
            })
          } else {
            cart.addItem({ productId, count: 1 })
            const redirectRoute = operator.operatorId ? RouteNames.serviceBasket : RouteNames.basket
            if (router.currentRoute.name !== redirectRoute) {
              await router.push({ name: redirectRoute })
            }
          }
        }
      } else {
        throw new Error('Нет barcode')
      }
    } else if ('loyaltyCardCodeAdded' in data) {
      log('Обрабатываем результат сканирования карты лояльности', data)
      const cardcode = data.loyaltyCardCodeAdded.code
      if (cardcode) {
        await customer.setCardNumber(cardcode)
      } else {
        throw new Error('Ошибка сканирования карты лояльности')
      }
    } else if ('personalCodeAdded' in data) {
      log('Обрабатываем результат сканирования кода оператора', data)
      const code = data.personalCodeAdded.code
      if (code) {
        await operator.setPersonalCode(code)
        if (operator.operatorId && router.currentRoute.name !== RouteNames.serviceBasket) {
          await router.push({ name: RouteNames.serviceBasket })
        }
      } else {
        throw new Error('Ошибка сканирования кода оператора')
      }
    } else if ('yandexCodeAdded' in data) {
      log('Обрабатываем результат сканирования кода оператора', data)
      const code = data.yandexCodeAdded.code
      if (code) {
        cart.setShowYandexCodeText(false)
      } else {
        throw new Error('Ошибка сканирования кода оператора')
      }
    }
  }
)
