import { createModule } from 'vuexok'
import store from '.'
import { apolloClient } from '@/plugins/apollo/default'
import { Maybe } from 'graphql/jsutils/Maybe'
import { SubscribeOptions } from '@/core/subscribeHelpers'
import { terminalService } from '@/store/terminalService'
import { logBreadcrumb } from '@/core/logger'
import { settingsModule } from '@/store/settings'
import { ObservableQuery } from '@apollo/client/core'
import {
  BannerFragment, FragmentTerminalFragment,
  LogoFragment,
  terminal,
  TerminalQuery,
  TerminalQueryVariables,
  terminalUpdated,
  TerminalUpdatedSubscription,
  TerminalUpdatedSubscriptionVariables
} from '@/graphql/default/terminal.graphql'
import { cloneDeep } from 'lodash-es'
import router, { RouteNames } from '@/router'

type State = {
  terminal: TerminalQuery['terminal'],
}

type Getters = {
  terminal: Maybe<FragmentTerminalFragment>,
  logo: Maybe<LogoFragment>,
  banners: Maybe<Maybe<BannerFragment>[]>,
  foodCardAuthData: Maybe<FragmentTerminalFragment['foodcardAuthData']>
  yandexAuthData: Maybe<FragmentTerminalFragment['yandexAuthData']>
  yandexBadgeAuthData: Maybe<FragmentTerminalFragment['yandexBadgeAuthData']>
  sberbankAuthData: Maybe<FragmentTerminalFragment['sberbankAuthData']>
  nalunchAuthData: Maybe<FragmentTerminalFragment['nalunchAuthData']>
}

const tag = 'terminalModule'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

const state: State = {
  terminal: null
}

let observableQuery: Maybe<ObservableQuery<TerminalQuery, TerminalQueryVariables>> = null

export const terminalModule = createModule(tag, {
  namespaced: true,
  state,
  mutations: {
    setTerminal(state, terminal: State['terminal']) {
      log('запоминаем Terminal', terminal)
      state.terminal = cloneDeep(terminal)
    }
  },

  actions: {
    async startWatchingSubscriptionsAndQueries() {
      log('Запускаем отслеживание состояния')
      observableQuery = apolloClient.watchQuery<TerminalQuery, TerminalQueryVariables>({
        query: terminal,
        errorPolicy: 'all',
        pollInterval: settingsModule.state.publicSettings.terminalSettingsUpdateInterval
      })
      observableQuery.subscribe(({ data: { terminal } }) => {
        terminalModule.mutations.setTerminal(terminal)
      })
      observableQuery.subscribeToMore(new SubscribeOptions<TerminalQuery,
        TerminalUpdatedSubscription,
        TerminalUpdatedSubscriptionVariables,
        TerminalQueryVariables>({
          type: 'updated',
          subscribeDocument: terminalUpdated,
          queryDocument: terminal,
          variables: {
            macAddress: await terminalService.getMAC()
          }
        }))
    }
  },

  getters: {
    terminal(state): Getters['terminal'] {
      return state.terminal?.edges[0]?.node
    },
    logo(state, getters: Getters): Getters['logo'] {
      const node = getters.terminal?.logos.edges[0]?.node
      if (node) {
        return {
          ...node,
          image: settingsModule.getters.mediaPath + node.image
        }
      }
      return null
    },
    banners(state, getters: Getters):Getters['banners'] {
      return getters.terminal?.banners?.edges?.map(edge => {
        const node = edge?.node
        if (node) {
          return {
            ...node,
            image: settingsModule.getters.mediaPath + node.image
          }
        }
        return null
      })
    },
    foodCardAuthData(state, getters: Getters): Getters['foodCardAuthData'] {
      return getters.terminal?.foodcardAuthData
    },
    yandexGoAuthData(state, getters: Getters): Getters['yandexAuthData'] {
      return getters.terminal?.yandexAuthData
    },
    yandexBagdeAuthData(state, getters: Getters): Getters['yandexBadgeAuthData'] {
      return getters.terminal?.yandexBadgeAuthData
    },
    sberbankAuthData(state, getters: Getters): Getters['sberbankAuthData'] {
      return getters.terminal?.sberbankAuthData
    },
    nalunchAuthData(state, getters: Getters): Getters['nalunchAuthData'] {
      return getters.terminal?.nalunchAuthData
    }
  }
})

terminalModule.register(store)

terminalModule.watch((state, getters) => getters.foodCardAuthData,
  (foodCardAuthData) => {
    log('Обрабатываем изменение foodCardAuthData', foodCardAuthData)
    if (foodCardAuthData) {
      const { authId, authToken } = foodCardAuthData
      terminalService.saveFoodcardAuthData({ authToken, authId })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.yandexGoAuthData,
  (yandexGoAuthData) => {
    log('Обрабатываем изменение yandexGoAuthData', yandexGoAuthData)
    if (yandexGoAuthData) {
      const { authToken, mcc } = yandexGoAuthData
      terminalService.saveYandexGoAuthData({ authToken, mcc })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.yandexBagdeAuthData,
  (yandexBadgeAuthData) => {
    log('Обрабатываем изменение yandexBagdeAuthData', yandexBadgeAuthData)
    if (yandexBadgeAuthData) {
      const { authToken } = yandexBadgeAuthData
      terminalService.saveYandexBadgeAuthData({ authToken })
    }
  }, { deep: true, immediate: true })

terminalModule.watch((state, getters) => getters.nalunchAuthData,
  (nalunchAuthData) => {
    log('Обрабатываем изменение nalunchAuthData', nalunchAuthData)
    if (nalunchAuthData) {
      const { authToken } = nalunchAuthData
      terminalService.saveNaLunchAuthData({ authToken })
    }
  }, { deep: true, immediate: true })

terminalModule.watch(
  (state1, getters) => getters.terminal?.isDemoMode,
  (isDemoMode) => {
    if (isDemoMode && router.currentRoute.name === RouteNames.lock) {
      router.push({ name: RouteNames.catalog })
    }
  },
  { immediate: true }
)

export default terminalModule
