import { createModule } from 'vuexok'
import { merge } from 'lodash-es'
import store from '@/store/index'
import { parse } from 'envfile'
import { logBreadcrumb } from '@/core/logger'
import { asyncDebounce } from '@/core/asynsDebounce'

/**
 * Настройки не предназначенные для изменения после сборки
 */
export type RequiredSettings = {
  readonly BASE_URL: string,
  SENTRY_DSN?: string,
}

export type PublicSettings = {
  /**
   * http адрес основного сервиса
   */
  GRAPHQL_HTTP: string,
  /**
   * ws адрес основного сервиса
   */
  GRAPHQL_WS: string,
  /**
   * http адреса локального терминала
   */
  GRAPHQL_HTTP_TERMINAL: string,
  /**
   * ws адреса локального терминала
   */
  GRAPHQL_WS_TERMINAL: string,
  /**
   * версия настроек
   */
  ENV_VERSION: string | 'default',

  /**
   * время перехода в режим ожидания
   */
  idleTime: number,
  /**
   * Выключить задачи бездействия
   */
  disableIdleTasks: boolean,
  /**
   * Отобразить ошибки в UI
   */
  showAllErrors: boolean,
  /**
   * Как часто проверять наличие обновления (помимо проверки во время бездействия)
   */
  howOftenToCheckForAppUpdates: number,
  /**
   * Продолжительность смены банера на главной странице
   */
  bannerDuration: number,
  /**
   * Таймаут демонстрации настоящего чека до переадресации
   */
  receiptDisplayTime: number,
  /**
   * Таймаут демонстрации ошибки оплаты до переадресации
   */
  paymentErrorDisplayTime: number,
  /**
   * Таймаут ожидания чека до переадресации
   */
  waitingTimeForReceipt: number,
  /**
   * Время актуальности корзины после оплаты
   */
  timeOfCartValidityAfterPayment: number
  /**
   * Время интервала обновления состояния терминала в милисекундах
   */
  terminalSettingsUpdateInterval: number
}

type State = {
  /**
   * Настройки поведения приложения
   */
  publicSettings: PublicSettings,
  readonly requiredSettings: RequiredSettings
}

type Getters = {
  isEnableSentry: boolean,
  mediaPath?: string,
}

const stateInit = (): State => ({
  /**
   * Дефолтные значения настроек
   *
   * дефолтные boolean значения должны быть всегда false
   */
  publicSettings: {
    GRAPHQL_HTTP: process.env.VUE_APP_GRAPHQL_HTTP || 'http://GRAPHQL_HTTP',
    GRAPHQL_WS: process.env.VUE_APP_GRAPHQL_WS || 'ws://GRAPHQL_WS',
    GRAPHQL_HTTP_TERMINAL: process.env.VUE_APP_GRAPHQL_HTTP_TERMINAL || 'http://GRAPHQL_HTTP_TERMINAL',
    GRAPHQL_WS_TERMINAL: process.env.VUE_APP_GRAPHQL_WS_TERMINAL || 'ws://GRAPHQL_WS_TERMINAL',
    ENV_VERSION: process.env.VUE_APP_ENV_VERSION || 'default',

    idleTime: 2 * 6e4,
    disableIdleTasks: false,
    showAllErrors: process.env.NODE_ENV !== 'production',
    howOftenToCheckForAppUpdates: 30 * 6e4,
    bannerDuration: 5e3,
    receiptDisplayTime: 20e3,
    paymentErrorDisplayTime: 20e3,
    waitingTimeForReceipt: 30 * 1e3,
    timeOfCartValidityAfterPayment: 5 * 6e4,
    terminalSettingsUpdateInterval: 22 * 60 * 6e4
  },
  requiredSettings: {
    BASE_URL: process.env.BASE_URL || '',
    SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN || process.env.SENTRY_DSN
  }
})

const tag = 'settingsModule'

const log = (...arg:any[]) => logBreadcrumb({ tag, color: 'blue' }, ...arg)

export const settingsModule = createModule(tag, {
  namespaced: true,
  state: stateInit,
  mutations: {
    reset(state) {
      log('Сбрасываем настройки')
      Object.assign(state, stateInit())
    },
    setPublicSettings(state, publicSettings: Partial<{[N in keyof PublicSettings]:PublicSettings[N]}>) {
      log('Применяем настройки', publicSettings)
      const newSettings = Object.entries(publicSettings)
        .reduce((previousValue:Partial<PublicSettings>, [name, value]) => {
          if ((name as keyof PublicSettings) in state.publicSettings) {
            const originalType = typeof state.publicSettings[(name as keyof PublicSettings)]

            if (originalType === 'number') {
              return Object.assign(previousValue, { [name]: Number(value) || 0 })
            }
            if (originalType === 'string') {
              return Object.assign(previousValue, { [name]: String(value) })
            }
            if (originalType === 'boolean') {
              return Object.assign(previousValue, { [name]: Boolean(value) })
            }
          }
          return previousValue
        }, {})
      state.publicSettings = merge(state.publicSettings, newSettings)
      log('Применены новые настройки', newSettings)
    }
  },
  getters: {
    isEnableSentry(state): Getters['isEnableSentry'] {
      return Boolean(process.env.NODE_ENV === 'production' && state.requiredSettings.SENTRY_DSN)
    },
    mediaPath(state): Getters['mediaPath'] {
      return state.publicSettings.GRAPHQL_HTTP.replace('graphql', 'media')
    }
  },
  actions: {
    /**
     * Загрузка файла с настройками
     */
    async loadPublicSettings(): Promise<void> {
      log('Загружаем настройки')
      const response = await fetch('settings')
      const text = await response.text()
      const settings:State['publicSettings'] = merge<Record<string, never>,
        State['publicSettings'], Partial<State['publicSettings']>>(
          {},
          settingsModule.state.publicSettings,
          parse(text)
        )
      settingsModule.mutations.setPublicSettings(settings)
    },
    /**
     * Получение настройки с предварительной загрузкой с сервера
     */
    async publicSettings(): Promise<State['publicSettings']> {
      if (settingsModule.state.publicSettings.ENV_VERSION === 'default') {
        await debounceLoadPublicSettings()
      }
      return settingsModule.state.publicSettings
    }
  }
})

settingsModule.register(store)

export const debounceLoadPublicSettings = asyncDebounce(
  settingsModule.actions.loadPublicSettings,
  300
)

settingsModule.watch(
  (state) => state.publicSettings.ENV_VERSION,
  (ENV_VERSION, old) => {
    log('Обрабатываем изменение ENV_VERSION')
    if (old?.length) {
      window.document.title = window.document.title.replace(old, ENV_VERSION)
    } else {
      window.document.title = `${ENV_VERSION} ${window.document.title}`
    }
  },
  {
    immediate: true
  }
)

// todo: сохранять настройки в localStorage
